import React from 'react';
import { Link } from 'gatsby';
import './Logo.scss';

export const Logo = props => {
  return (
    <Link to="/" className="logo">
    <svg version="1.1" id="Layer_1" x="0px" y="0px"
 viewBox="0 0 348.8 103.6" fill="currentColor">
    <g>
    <path d="M347,46.2c-2.1-2.6-2.6-3.1-4.1-4.6s-3-2.8-4.7-4s-3.5-2.1-5.5-2.8c-1.9-0.7-4-1-6.3-1c-2.6,0-4.9,0.5-7,1.4
      s-3.9,2.2-5.3,3.9c-0.7,0.8-1.3,1.6-1.8,2.6c0,0,0-0.1-0.1-0.1c-1.5-1.5-3-2.8-4.7-4s-3.5-2.1-5.5-2.8c-1.9-0.7-4-1-6.3-1
      c-2.6,0-4.9,0.5-7,1.4s-3.9,2.2-5.3,3.9s-2.5,3.6-3.3,5.8c-0.8,2.2-1.2,4.6-1.2,7.2c0,0.9,0.1,1.9,0.2,2.8
      c-0.3,0.2-0.5,0.3-0.8,0.4c-0.7,0.3-1.4,0.5-2.1,0.5s-1.3-0.1-1.9-0.3s-1.1-0.5-1.5-0.9s-0.8-1-1-1.6c-0.3-0.6-0.4-1.4-0.4-2.3
      c0-0.4,0.1-1.3,0.4-2.6c0.3-1.4,0.7-3.1,1.1-5.1s0.9-4.3,1.4-6.8s1-5.1,1.4-7.7c0.4-2.6,0.8-5.3,1.1-7.9s0.4-5.1,0.4-7.4
      c0-0.8-0.1-1.5-0.3-2s-0.5-0.9-0.8-1.3s-0.8-0.5-1.2-0.7c-0.5-0.1-1-0.2-1.6-0.2c-0.8,0-1.5,0.1-2.2,0.2c-0.7,0.1-1.4,0.4-1.9,0.8
      c-0.6,0.4-1,1-1.4,1.7c-0.4,0.7-0.6,1.7-0.6,2.9c-0.1,1.1-0.2,2.7-0.4,4.8s-0.4,4.3-0.7,6.9c-0.3,2.5-0.7,5.2-1.1,8.1
      s-1,5.7-1.6,8.5s-1.3,5.5-2.1,8.1c-0.8,2.6-1.7,4.8-2.7,6.8c-1,2-2.1,3.5-3.4,4.7c-1.2,1.2-2.6,1.8-4.1,1.8c-0.9,0-1.6-0.3-2.2-0.9
      s-1.1-1.5-1.4-2.7s-0.6-2.6-0.8-4.3c-0.2-1.7-0.2-3.6-0.2-5.7c0-2.1,0.1-4.3,0.3-6.8s0.4-5,0.7-7.5c0.3-2.5,0.6-5,0.9-7.4
      s0.7-4.5,0.9-6.4c0.3-1.9,0.5-3.4,0.7-4.6c0.2-1.2,0.3-1.9,0.3-2.1c0-0.5-0.1-0.9-0.2-1.3c-0.1-0.4-0.4-0.7-0.7-1s-0.8-0.5-1.4-0.7
      c-0.6-0.2-1.3-0.2-2.2-0.2c-0.9,0-1.8,0.1-2.5,0.3s-1.3,0.6-1.9,1.1c-0.5,0.5-1,1.2-1.4,2.1c-0.4,0.9-0.7,1.9-1,3.2
      c-1.5,8-3.3,15.1-5.2,21.4c-0.6,1.8-1.2,3.5-1.8,5.3c-0.6,1.7-1.3,3.4-2,5s-1.5,3.1-2.2,4.4c-0.8,1.4-1.6,2.5-2.4,3.5
      s-1.7,1.8-2.6,2.3s-1.8,0.8-2.8,0.8s-1.8-0.3-2.5-0.9c-0.7-0.6-1.3-1.4-1.8-2.5c-0.5-1.1-0.8-2.3-1-3.8s-0.3-3-0.3-4.7
      c0-2,0.2-4.2,0.6-6.6c0.4-2.4,0.9-4.8,1.5-7.3s1.2-4.9,1.9-7.3s1.3-4.6,1.9-6.6s1.1-3.8,1.5-5.4s0.6-2.7,0.6-3.5
      c0-0.7-0.1-1.3-0.2-1.7c-0.2-0.4-0.4-0.7-0.7-0.9c-0.3-0.2-0.6-0.4-1.1-0.4s-0.9-0.1-1.4-0.1c-0.6,0-1.3,0-1.9,0.1
      s-1.2,0.3-1.8,0.6c-0.6,0.4-1.2,0.9-1.8,1.7s-1.2,1.8-1.8,3.2c-1,2.4-1.9,4.9-2.6,7.5c-0.8,2.6-1.4,5.2-2,7.8
      c-0.5,2.6-0.9,5.2-1.2,7.9c-0.3,2.6-0.4,5.2-0.4,7.6c0,1.7,0.1,3.3,0.2,4.9c-1.4,2.1-2.7,3.8-3.9,5.2c-1.2,1.5-2.4,2.7-3.4,3.7
      s-1.9,1.8-2.7,2.4c-0.8,0.6-1.6,1.1-2.3,1.5c0.6-4,1.2-7.7,1.8-11.2c0.2-1.5,0.5-3,0.7-4.4c0.3-1.5,0.5-2.9,0.8-4.2
      s0.5-2.5,0.7-3.6s0.4-1.9,0.5-2.5c0.1-0.3,0.1-0.5,0.1-0.7c0-0.7-0.2-1.2-0.7-1.5s-1.1-0.5-1.8-0.5c-0.6,0-1.3,0.1-1.9,0.3
      s-1.3,0.5-2,0.9s-1.2,0.9-1.7,1.6c-0.5,0.6-0.8,1.3-1,2.1l-2.5,10.3c-0.1,0.8-0.3,1.7-0.6,2.8s-0.7,2.2-1.2,3.3s-1.1,2-1.8,2.7
      c-0.7,0.8-1.5,1.1-2.4,1.1c-0.5,0-1-0.1-1.3-0.4s-0.6-0.6-0.8-1.1c-0.2-0.4-0.4-0.9-0.4-1.5c-0.1-0.5-0.1-1.1-0.1-1.7
      c0-1.1,0.1-2.4,0.3-3.9s0.4-3,0.8-4.5s0.7-3,1.1-4.5c0.4-1.5,0.8-2.7,1.2-3.8c0.1-0.4,0.2-0.7,0.3-1c0.1-0.3,0.1-0.6,0.1-0.8
      c0-0.9-0.3-1.5-0.9-1.9s-1.3-0.6-2.1-0.6c-1,0-1.9,0.3-3,0.8c-1,0.6-1.8,1.4-2.3,2.4c-0.7,1.5-1.4,2.9-1.9,4.4s-1,2.9-1.4,4.5
      s-0.7,3.2-0.9,4.9c0,0.2,0,0.4-0.1,0.6c-0.9,0.7-1.7,1.3-2.6,1.8c-0.9,0.6-1.8,1.1-2.6,1.5c-0.9,0.5-1.7,0.9-2.6,1.4
      c0.7-1.1,1.3-2.1,1.7-3.1c0.4-1,0.8-2,1-3s0.4-2.1,0.5-3.1s0.1-2.2,0.1-3.4c0-1.4-0.2-2.8-0.6-4.1c-0.4-1.3-1-2.5-1.7-3.5
      s-1.7-1.8-2.7-2.4c-1.1-0.6-2.3-0.9-3.7-0.9c-1.1,0-2.2,0.5-3.3,1.5c-1.1,1-2.3,2.3-3.6,4l-0.6-0.4c1.6-2,3.3-4.1,4.9-6.4
      s3.1-4.6,4.5-6.9s2.4-4.7,3.3-7.1c0.8-2.4,1.2-4.7,1.2-6.9c0-1.6-0.1-3.1-0.3-4.5s-0.6-2.6-1.2-3.7c-0.6-1-1.4-1.9-2.5-2.5
      c-1.1-0.6-2.5-0.9-4.3-0.9c-1.6,0-3.1,0.6-4.4,1.9s-2.6,3-3.8,5.1s-2.2,4.6-3.2,7.3s-1.8,5.5-2.6,8.4c-0.8,2.9-1.4,5.8-2,8.6
      c-0.6,2.8-1,5.4-1.4,7.7c-0.6,3.4-1,6.6-1.3,9.4c-0.2,1.7-0.4,3.2-0.5,4.6c-0.6,0.5-1.3,0.9-1.9,1.3c-0.9,0.6-1.8,1.1-2.6,1.5
      c-0.9,0.5-1.7,0.9-2.6,1.4c0.7-1.1,1.3-2.1,1.7-3.1c0.4-1,0.8-2,1-3s0.4-2.1,0.5-3.1s0.1-2.2,0.1-3.4c0-1.4-0.2-2.8-0.6-4.1
      c-0.4-1.3-1-2.5-1.7-3.5s-1.7-1.8-2.7-2.4c-1.1-0.6-2.3-0.9-3.7-0.9c-1.1,0-2.2,0.5-3.3,1.5c-1.1,1-2.3,2.3-3.6,4l-0.6-0.4
      c1.6-2,3.3-4.1,4.9-6.4s3.1-4.6,4.5-6.9s2.4-4.7,3.3-7.1c0.8-2.4,1.2-4.7,1.2-6.9c0-1.6-0.1-3.1-0.3-4.5s-0.6-2.6-1.2-3.7
      c-0.6-1-1.4-1.9-2.5-2.5c-1.1-0.6-2.5-0.9-4.3-0.9c-1.6,0-3.1,0.6-4.4,1.9s-2.6,3-3.8,5.1c-1.2,2.1-2.2,4.6-3.2,7.3
      c-0.9,2.7-1.8,5.5-2.6,8.4c-0.8,2.9-1.4,5.8-2,8.6c-0.6,2.8-1,5.4-1.4,7.7c-0.6,3.4-1,6.6-1.3,9.4c-0.3,2.5-0.5,4.6-0.7,6.5
      c-1.1,0.8-2.2,1.5-3.3,2.2c-1.5,0.8-3,1.5-4.6,2.1s-3,0.8-4.4,0.8c-0.4,0-0.9,0-1.2-0.1c-0.4-0.1-0.8-0.3-1.1-0.5
      c-0.4-0.2-0.7-0.6-1-1c-0.3-0.4-0.7-0.9-1-1.6c2-0.6,3.9-1.3,5.7-2.4s3.3-2.2,4.5-3.4c1.3-1.3,2.3-2.7,3-4.1
      c0.7-1.5,1.1-2.9,1.1-4.4c0-1.3-0.2-2.5-0.7-3.6s-1.1-2-2-2.8c-0.8-0.8-1.8-1.4-2.9-1.8s-2.3-0.6-3.7-0.6c-1.6,0-3.1,0.3-4.4,0.9
      c-1.3,0.6-2.5,1.3-3.6,2.3c-1.1,1-2,2.1-2.8,3.4c-0.8,1.3-1.5,2.6-2,4s-0.9,2.8-1.2,4.3c-0.3,1.5-0.4,2.9-0.4,4.2
      c0,0.7,0,1.3,0.1,2c-0.3,0.2-0.7,0.4-1,0.5c-0.7,0.3-1.4,0.5-2.1,0.5s-1.3-0.1-1.9-0.3s-1.1-0.5-1.5-0.9s-0.8-1-1-1.6
      c-0.3-0.6-0.4-1.4-0.4-2.3c0-0.4,0.1-1.3,0.4-2.6c0.3-1.4,0.7-3.1,1.1-5.1s0.9-4.3,1.4-6.8s1-5.1,1.4-7.7c0.4-2.6,0.8-5.3,1.1-7.9
      c0.3-2.6,0.4-5.1,0.4-7.4c0-0.8-0.1-1.5-0.3-2c-0.2-0.5-0.5-0.9-0.8-1.3s-0.8-0.5-1.2-0.7c-0.5-0.1-1-0.2-1.6-0.2
      c-0.8,0-1.5,0.1-2.2,0.2c-0.7,0.1-1.4,0.4-1.9,0.8c-0.6,0.4-1,1-1.4,1.7c-0.4,0.7-0.6,1.7-0.7,2.9c-0.1,1.1-0.2,2.7-0.4,4.8
      s-0.4,4.3-0.7,6.9c-0.3,2.5-0.7,5.2-1.1,8.1s-1,5.7-1.6,8.5c-0.6,2.8-1.3,5.5-2.1,8.1c-0.8,2.6-1.7,4.8-2.7,6.8
      c-1,2-2.1,3.5-3.4,4.7c-1.2,1.2-2.6,1.8-4.1,1.8c-0.9,0-1.6-0.3-2.2-0.9s-1.1-1.5-1.4-2.7c-0.4-1.2-0.6-2.6-0.8-4.3
      c-0.2-1.7-0.2-3.6-0.2-5.7c0-2.1,0.1-4.3,0.3-6.8s0.4-5,0.7-7.5c0.3-2.5,0.6-5,0.9-7.4c0.3-2.4,0.7-4.5,0.9-6.4
      c0.3-1.9,0.5-3.4,0.7-4.6c0.2-1.2,0.3-1.9,0.3-2.1c0-0.5-0.1-0.9-0.2-1.3c-0.1-0.4-0.4-0.7-0.7-1c-0.3-0.3-0.8-0.5-1.4-0.7
      c-0.6-0.2-1.3-0.2-2.2-0.2c-0.9,0-1.8,0.1-2.5,0.3s-1.3,0.6-1.9,1.1c-0.5,0.5-1,1.2-1.4,2.1c-0.4,0.9-0.7,1.9-1,3.2
      c-1.5,8-3.3,15.1-5.2,21.4c-0.6,1.8-1.2,3.5-1.8,5.3c-0.6,1.7-1.3,3.4-2,5c-0.7,1.6-1.5,3.1-2.2,4.4c-0.8,1.4-1.6,2.5-2.4,3.5
      s-1.7,1.8-2.6,2.3s-1.8,0.8-2.8,0.8s-1.8-0.3-2.5-0.9c-0.7-0.6-1.3-1.4-1.8-2.5c-0.5-1.1-0.8-2.3-1-3.8c-0.2-1.4-0.3-3-0.3-4.7
      c0-2,0.2-4.2,0.6-6.6s0.9-4.8,1.5-7.3c0.6-2.5,1.2-4.9,1.9-7.3c0.7-2.4,1.3-4.6,1.9-6.6c0.6-2,1.1-3.8,1.5-5.4s0.6-2.7,0.6-3.5
      c0-0.7-0.1-1.3-0.2-1.7s-0.4-0.7-0.7-0.9c-0.3-0.2-0.6-0.4-1.1-0.4s-0.9-0.1-1.4-0.1c-0.6,0-1.3,0-1.9,0.1s-1.2,0.3-1.8,0.6
      c-0.6,0.4-1.2,0.9-1.8,1.7c-0.6,0.8-1.2,1.8-1.8,3.2c-1,2.4-1.9,4.9-2.6,7.5c-0.8,2.6-1.4,5.2-2,7.8s-0.9,5.2-1.2,7.9
      c-0.3,2.6-0.4,5.2-0.4,7.6c0,4.1,0.3,7.6,1,10.6c0.6,3,1.5,5.6,2.6,7.6c1.1,2,2.3,3.5,3.7,4.6s2.9,1.5,4.4,1.5
      c2.7,0,5.1-0.5,7.4-1.4s4.2-2.5,5.9-4.7s3.2-5.1,4.5-8.7c1.3-3.6,2.3-8.1,3.2-13.4c-0.1,0.8-0.2,1.7-0.3,2.6c-0.1,1-0.2,2-0.2,3.2
      c0,3.1,0.2,6,0.7,8.7c0.4,2.7,1.2,5.1,2.1,7.1c1,2,2.2,3.6,3.8,4.8s3.5,1.8,5.7,1.8c1.8,0,3.5-0.3,5.1-1s3.2-1.6,4.7-2.8
      s2.9-2.7,4.3-4.5c1.3-1.7,2.6-3.7,3.8-5.9c0.1,1.1,0.4,2.1,0.7,2.9c0.4,0.8,0.8,1.5,1.3,2c0.5,0.5,1.1,0.9,1.8,1.1
      c0.7,0.2,1.4,0.4,2.2,0.4c1.6,0,3.3-0.6,5.2-1.7c0.6-0.4,1.2-0.8,1.9-1.3c0.5,1.1,1.1,2.1,1.9,3c1.1,1.3,2.5,2.4,4.1,3.2
      c1.7,0.8,3.6,1.2,5.8,1.2c1.4,0,2.8-0.2,4.2-0.5s2.7-0.7,4-1.3s2.5-1.1,3.7-1.8c0.6-0.4,1.2-0.7,1.8-1.1c1.3,0.7,2.7,1.4,4.1,2
      c1.6,0.7,3.4,1.3,5.3,1.8c2,0.6,3.9,0.8,5.9,0.8h0.9c0.9-0.4,1.7-0.7,2.5-1.1c0.7-0.3,1.4-0.7,2.1-1.1c0.7-0.4,1.2-0.8,1.6-1.2
      c1.6-0.4,3.2-0.8,4.7-1.3c1.5-0.5,2.9-1,4.2-1.7c0.3-0.2,0.6-0.3,1-0.5c0,0.5,0,1-0.1,1.5c1.7,1,3.5,1.9,5.3,2.7
      c1.6,0.7,3.4,1.3,5.3,1.8c2,0.6,3.9,0.8,5.9,0.8h0.9c0.9-0.4,1.7-0.7,2.5-1.1c0.7-0.3,1.4-0.7,2.1-1.1c0.7-0.4,1.2-0.8,1.6-1.2
      c1.6-0.4,3.2-0.8,4.7-1.3c1.5-0.5,2.9-1,4.2-1.7c0.8-0.4,1.5-0.8,2.2-1.2c0.1,0.6,0.3,1.2,0.5,1.8c0.5,1.2,1.1,2.3,2,3.1
      c0.8,0.9,1.8,1.5,2.8,2s2.2,0.7,3.3,0.7c1.3,0,2.6-0.3,4-1c1.4-0.7,2.7-1.6,4-2.7l-0.9,3.3c-1.7,1.4-3.4,2.8-5,4.4
      s-3.2,3.1-4.7,4.6s-2.9,3.1-4.2,4.7s-2.4,3.1-3.3,4.6s-1.7,2.9-2.2,4.2s-0.8,2.5-0.8,3.6c0,1.5,0.3,2.7,0.8,3.7s1.2,1.6,2,2.1
      s1.6,0.8,2.6,1s1.8,0.2,2.6,0.2c2,0,3.9-0.3,5.5-1s3.2-1.7,4.5-2.9s2.6-2.7,3.6-4.5s2-3.6,2.8-5.7s1.6-4.3,2.2-6.7s1.1-4.8,1.6-7.3
      c2.3-1.4,4.4-2.9,6.4-4.4c0.8-0.6,1.7-1.3,2.6-2s1.7-1.5,2.5-2.2c0.8-0.8,1.5-1.5,2.2-2.3c0.4-0.5,0.8-0.9,1.1-1.4
      c0.5,1.9,1.2,3.6,1.9,5.1c1.1,2,2.3,3.5,3.7,4.6s2.9,1.5,4.4,1.5c2.7,0,5.1-0.5,7.4-1.4s4.2-2.5,5.9-4.7s3.2-5.1,4.5-8.7
      c1.3-3.6,2.3-8.1,3.2-13.4c-0.1,0.8-0.2,1.7-0.3,2.6s-0.2,2-0.2,3.2c0,3.1,0.2,6,0.7,8.7c0.4,2.7,1.2,5.1,2.1,7.1
      c1,2,2.2,3.6,3.8,4.8s3.5,1.8,5.7,1.8c1.8,0,3.5-0.3,5.1-1c1.7-0.7,3.2-1.6,4.7-2.8s2.9-2.7,4.3-4.5c1.3-1.7,2.6-3.7,3.8-5.9
      c0.1,1.1,0.4,2.1,0.7,2.9c0.4,0.8,0.8,1.5,1.3,2c0.5,0.5,1.1,0.9,1.8,1.1s1.4,0.4,2.2,0.4c1.6,0,3.3-0.6,5.2-1.7
      c0.5-0.3,1.1-0.7,1.6-1.1c0.3,0.8,0.8,1.6,1.2,2.4c0.9,1.5,2.1,2.6,3.6,3.5c1.5,0.9,3.2,1.4,5.3,1.4c1.4,0,2.8-0.2,4-0.7
      s2.4-1.2,3.5-2s2-1.8,2.8-3c0.8-1.1,1.5-2.4,2.1-3.7c0.6-1.3,1-2.7,1.3-4.1s0.5-2.8,0.5-4.2c0-1.4-0.2-2.8-0.5-4.3
      s-0.9-2.9-1.5-4.3l0.3-0.4c0.8,0.6,1.9,1.3,3,2.1c1.2,0.8,2.4,1.7,3.6,2.7c0.3,0.2,0.5,0.4,0.8,0.6c-0.5,1.9-0.8,3.9-0.8,6
      c0,1.9,0.2,3.7,0.6,5.6s1.1,3.5,2.1,4.9c0.9,1.5,2.1,2.6,3.6,3.5c1.5,0.9,3.2,1.4,5.3,1.4c1.4,0,2.8-0.2,4-0.7s2.4-1.2,3.5-2
      s2-1.8,2.8-3c0.8-1.1,1.5-2.4,2.1-3.7c0.6-1.3,1-2.7,1.3-4.1s0.5-2.8,0.5-4.2c0-1.4-0.2-2.8-0.5-4.3s-0.9-2.9-1.5-4.3l0.3-0.4
      c0.8,0.6,1.9,1.3,3,2.1c1.2,0.8,2.4,1.7,3.6,2.7c1.2,0.9,2.4,1.9,3.4,2.9c1.1,1,1.9,1.9,2.6,2.7C346.3,51.2,349.1,48.8,347,46.2z
       M83.4,49.1c0-0.7,0.1-1.5,0.3-2.2c0.2-0.8,0.4-1.5,0.7-2.3c0.3-0.8,0.7-1.4,1.2-2s1.1-1.1,1.8-1.5s1.6-0.6,2.6-0.6
      c0.6,0,1,0.3,1.4,1s0.6,1.5,0.6,2.6c0,0.8-0.3,1.5-0.9,2.3c-0.6,0.8-1.3,1.5-2.2,2.2S87,49.9,86,50.3c-1,0.4-1.9,0.7-2.8,0.7
      C83.3,50.5,83.3,49.8,83.4,49.1z M111.1,33c0.2-1.3,0.5-2.6,0.8-4.1s0.7-3.1,1.1-4.8s0.8-3.3,1.2-5c0.4-1.6,0.9-3.2,1.4-4.7
      c0.5-1.5,0.9-2.8,1.4-3.9c0.5-1.1,1-2,1.5-2.7s1-1,1.5-1c0.4,0,0.7,0.1,0.9,0.4s0.4,0.6,0.5,0.9c0.1,0.3,0.2,0.6,0.2,0.9
      c0,0.3,0.1,0.5,0.1,0.6c-0.1,1.7-0.4,3.3-0.8,4.9s-0.9,3.2-1.5,4.7c-0.6,1.6-1.3,3.1-2.1,4.6c-0.8,1.5-1.5,2.9-2.3,4.4
      s-1.6,2.8-2.4,4.1s-1.5,2.6-2.1,3.8C110.6,35.3,110.8,34.2,111.1,33z M122.7,49.1c-0.1,1.1-0.3,2.1-0.5,3.2s-0.5,2.1-0.9,3.1
      c-0.4,1-0.8,1.9-1.3,2.7c-0.5,0.8-1.1,1.4-1.8,1.9c-0.7,0.5-1.4,0.7-2.2,0.7c-0.7,0-1.4-0.1-2.1-0.2c-0.8-0.1-1.5-0.2-2.1-0.4
      c-0.8-0.2-1.5-0.4-2.3-0.6c0.1-0.8,0.2-1.7,0.2-2.6c0.1-0.8,0.2-1.8,0.4-2.8c0.1-1.1,0.3-2.2,0.5-3.5c0.3-1.6,0.7-2.9,1.2-4
      c0.5-1.1,1.2-2,1.9-2.7c0.7-0.7,1.5-1.2,2.3-1.6c0.8-0.3,1.6-0.5,2.5-0.5c0.9,0,1.7,0.1,2.3,0.4s1.1,0.6,1.4,1s0.6,0.9,0.7,1.4
      s0.2,1.1,0.2,1.7C122.9,47.1,122.8,48,122.7,49.1z M144.5,33c0.2-1.3,0.5-2.6,0.8-4.1s0.7-3.1,1.1-4.8s0.8-3.3,1.2-5
      c0.4-1.6,0.9-3.2,1.4-4.7c0.5-1.5,0.9-2.8,1.4-3.9c0.5-1.1,1-2,1.5-2.7s1-1,1.5-1c0.4,0,0.7,0.1,0.9,0.4c0.2,0.3,0.4,0.6,0.5,0.9
      c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3,0.1,0.5,0.1,0.6c-0.1,1.7-0.4,3.3-0.8,4.9s-0.9,3.2-1.5,4.7c-0.6,1.6-1.3,3.1-2.1,4.6
      c-0.8,1.5-1.5,2.9-2.3,4.4s-1.6,2.8-2.4,4.1s-1.5,2.6-2.1,3.8C144,35.3,144.2,34.2,144.5,33z M156.1,49.1c-0.1,1.1-0.3,2.1-0.5,3.2
      s-0.5,2.1-0.9,3.1c-0.4,1-0.8,1.9-1.3,2.7c-0.5,0.8-1.1,1.4-1.8,1.9c-0.7,0.5-1.4,0.7-2.2,0.7c-0.7,0-1.4-0.1-2.1-0.2
      c-0.8-0.1-1.5-0.2-2.1-0.4c-0.8-0.2-1.5-0.4-2.3-0.6c0.1-0.8,0.2-1.7,0.2-2.6c0.1-0.8,0.2-1.8,0.4-2.8c0.1-1.1,0.3-2.2,0.5-3.5
      c0.3-1.6,0.7-2.9,1.2-4c0.5-1.1,1.2-2,1.9-2.7s1.5-1.2,2.3-1.6c0.8-0.3,1.6-0.5,2.5-0.5c0.9,0,1.7,0.1,2.3,0.4s1.1,0.6,1.4,1
      c0.3,0.4,0.6,0.9,0.7,1.4s0.2,1.1,0.2,1.7C156.3,47.1,156.2,48,156.1,49.1z M182.1,79.7c-0.3,1.2-0.7,2.4-1.2,3.7s-1,2.5-1.6,3.7
      s-1.3,2.3-2,3.2s-1.5,1.7-2.4,2.3s-1.8,0.9-2.8,0.9c-0.9,0-1.4-0.4-1.4-1.3c0-0.5,0.2-1.2,0.5-2s0.8-1.7,1.4-2.7s1.3-1.9,2.1-3
      s1.7-2,2.6-3s1.8-1.9,2.8-2.7s1.9-1.6,2.8-2.2C182.7,77.4,182.5,78.5,182.1,79.7z M297.5,52.4c-0.3,1.5-0.8,2.8-1.4,4.1
      s-1.2,2.2-2,3.1s-1.5,1.2-2.3,1.2c-0.9,0-1.7-0.2-2.4-0.6s-1.2-0.9-1.6-1.5c-0.4-0.6-0.7-1.4-0.9-2.3c-0.2-0.9-0.3-1.8-0.3-2.8
      c0-1.4,0.2-2.9,0.6-4.5c0.4-1.5,0.9-3,1.5-4.2c0.7-1.3,1.4-2.3,2.3-3.1s1.8-1.2,2.7-1.2c0.7,0,1.3,0.2,1.9,0.6
      c0.5,0.4,0.9,0.9,1.3,1.6c0.3,0.7,0.6,1.5,0.7,2.4c0.2,0.9,0.2,1.9,0.2,3C298,49.5,297.9,50.9,297.5,52.4z M328.2,52.4
      c-0.3,1.5-0.8,2.8-1.4,4.1s-1.2,2.2-2,3.1s-1.5,1.2-2.3,1.2c-0.9,0-1.7-0.2-2.4-0.6s-1.2-0.9-1.6-1.5c-0.4-0.6-0.7-1.4-0.9-2.3
      c-0.2-0.9-0.3-1.8-0.3-2.8c0-1.4,0.2-2.9,0.6-4.5c0.4-1.5,0.9-3,1.5-4.2c0.7-1.3,1.4-2.3,2.3-3.1s1.8-1.2,2.7-1.2
      c0.7,0,1.3,0.2,1.9,0.6c0.5,0.4,0.9,0.9,1.3,1.6c0.3,0.7,0.6,1.5,0.7,2.4c0.2,0.9,0.2,1.9,0.2,3C328.7,49.5,328.6,50.9,328.2,52.4z
      "/>
    </g>
    </svg>
    </Link>
  );
}
